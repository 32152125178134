import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

// import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
// import { RenderOnDay } from '@hmn/rtl-web-core/components/RenderOnDay'
import SlidingWrapper from '@hmn/rtl-web-core/components/SlidingWrapper/SlidingWrapper.component'
// import { MonetizeAd } from '@hmn/rtl-web-core/components/third-party/MonetizeAd.component'
import { ModalGalleryContext as GalleryContext } from '@hmn/rtl-web-core/context/articleModalGallery'
import pageVariants from '@hmn/rtl-web-core/helpers/pageVariants'
import {
    useCrossDomainCommunication as usePreview,
    useHtmlContent,
    useInArticleAds,
    usePrevNextScrolling
} from '@hmn/rtl-web-core/hooks'
import useCategoryMapping from '@hmn/rtl-web-core/hooks/category/useCategoryMapping'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'
import usePrevNextInitialData from '@hmn/rtl-web-core/hooks/prev-next/usePrevNextInitialData'
import useInsertExchange from '@hmn/rtl-web-core/hooks/useInsertExchange'

import { insertAds as articleInsertAds, staticAds } from '@hmn/rtl-net-ui/components/Ad/config/article'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import { ArticleContent, ArticleCtaBlock, ArticleMeta } from '@hmn/rtl-net-ui/components/Article/components'
import { Divider } from '@hmn/rtl-net-ui/components/Divider'
import {
    categoryMappedWidgetIdSets,
    exchangeWidgetVariants,
    widgetIdSets
} from '@hmn/rtl-net-ui/components/ExchangeWidget'
import ExchangeWidget from '@hmn/rtl-net-ui/components/ExchangeWidget/ExchangeWidget.component'
import { HtmlContent } from '@hmn/rtl-net-ui/components/HtmlContent'
import LiveWidget from '@hmn/rtl-net-ui/components/LiveWidget/LiveWidget.component'
// import {
//     DynamicListalicaProizvoda,
//     listalicaProizvodaSourceVariants
// } from '@hmn/rtl-net-ui/components/ListalicaProizvoda'
import ModalGallery from '@hmn/rtl-net-ui/components/ModalGallery/ModalGallery.component'
import { TrackingCode } from '@hmn/rtl-net-ui/components/TrackingCode'

import { BaseLayout } from '../../layouts'
import { PrevNextArticle, Seo } from '..'
import CombinedArticleHead from '../CombinedArticleHead'
import WidgetVoyoSlider from '../LayoutBuilder/components/WidgetVoyoSlider.component'
import { MoreFromTheColumnBox, moreFromTheColumnBoxVariants } from '../MoreFromTheColumnBox'
// import { ReadMoreWidget } from '../ReadMoreWidget'
import { SelectedArticlesBox } from '../SelectedArticlesBox'
import { TaboolaFirstFrame, TaboolaProvider, TaboolaSecondFrame } from '../Taboola.component'
import { YouMightBeInterestedBox } from '../YouMightBeInterestedBox'

const voyoWidgetIds = process.env.NEXT_PUBLIC_VOYO_SLIDER_IDS?.split(',') || []

const {
    halfpage1,
    halfpage2,
    halfpage2alt,
    halfpage3,
    billboardTop,
    billboard1,
    billboard2,
    outOfPage1,
    outOfPage2,
    outOfPage3,
    interstitial,
    articleBottom,
    inPicture
} = staticAds

const halfpageSlidingProps = {
    offsetTop: 80,
    offsetBottom: 50,
    contentWidth: '100%',
    minimumFrameHeight: 600
}

function Article({ ssrData, navigation }) {
    const { article, lengthScore, author, moreFromTheColumn, articleVideo, articleGallery } = ssrData
    const { id } = article
    useEntityTypeDispatch('article')
    const { data: articleWithPreview } = usePreview(article, { type: 'preview', source: '@CMS' })
    const articleTags = useMemo(() => articleWithPreview?.tags || [], [articleWithPreview?.tags])

    /** ****************PREV-NEXT***************** */

    const { articleType, isPrevNext, isFirstPageview } = usePrevNextInitialData({
        article: articleWithPreview,
        appName: 'nethr'
    })

    const { contentRef, articlePage } = usePrevNextScrolling({
        offset: isFirstPageview ? -150 : -100 // because on first page change the title gets cut off
    })

    const htmlCont = useHtmlContent({
        docbook: articleWithPreview?.body,
        currentPage: articlePage
    })

    const { Content, currentPage, totalPages, blockList } = htmlCont
    /** **************** INSERT ELEMENTS ***************** */

    const insertAdsConfig = useMemo(() => {
        if (lengthScore?.lengthCategory === 'short') {
            return [articleInsertAds[0]]
        }
        if (lengthScore?.lengthCategory === 'medium') {
            return [articleInsertAds[0], { ...articleInsertAds[1], insertAtLeast: 2 }]
        }
        return articleInsertAds
    }, [lengthScore])
    // console.log('insertAdsConfig', insertAdsConfig)
    const insertAds = useInArticleAds({
        // targetingArguments: targetingArguments.ads,
        insertAdsConfig,
        AdSlotComponent: AdSlot,
        maxAdWidth: '700px',
        title: 'Tekst se nastavlja ispod oglasa'
    })
    /** **************** EXCHANGE WIDGETS ***************** */
    const endArticleWidgetIds = useCategoryMapping(categoryMappedWidgetIdSets.endarticleWidget)
    const inarticleWidgetIds = useCategoryMapping(categoryMappedWidgetIdSets.inarticleWidget)

    const insertExchange = useInsertExchange({
        key: inarticleWidgetIds,
        count: 1,
        appName: pageVariants.NET,
        ExchangeComponent: ExchangeWidget,
        variant: 'inarticle-alpha',
        every: 7
    })

    const articleExtended = articleWithPreview?.extended_attributes

    const insertElements = useMemo(() => [...insertAds, insertExchange].filter(Boolean), [insertAds, insertExchange])

    return (
        <BaseLayout navigationData={navigation}>
            <GalleryContext.Provider>
                <IsolatedEntityThirdParties
                    entityType="article"
                    entity={articleWithPreview}
                    articleType={articleType}
                    isPrevNext={isPrevNext}
                    appName="net.hr"
                    appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                    defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                    ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_NET}
                />
                <TrackingCode code={article?.extended_attributes?.tracking_code} />
                <Seo item={articleWithPreview} />
                <Row variant="article.main">
                    <Row variant="article.ads.billboard">
                        <AdSlot
                            {...billboardTop}
                            desktopOnly={['short', 'medium'].some(s => s === lengthScore.lengthCategory)}
                        />
                    </Row>

                    <Row variant="article.head">
                        <CombinedArticleHead
                            article={articleWithPreview}
                            articleVideo={articleVideo}
                            columnist={author}
                            inPictureAdConfig={inPicture}
                            articleGallery={articleGallery}
                        />

                        {/* ========= HEADER SIDEBAR ====== */}

                        <Column variant="article.head.aside" id="head_aside">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                <AdSlot {...halfpage1} desktopOnly />
                            </Media>
                        </Column>
                    </Row>

                    <Row variant="article.ads.billboard">
                        <AdSlot
                            {...billboard1}
                            desktopOnly={['short', 'medium'].some(s => s === lengthScore.lengthCategory)}
                        />
                    </Row>

                    <Row variant="article.mainContent">
                        <Row variant="article.mainContent.body">
                            <Column variant="article.content">
                                {/* @NOTE: className is used for upscore purposes */}
                                {/* // ========= PREV-NEXT TYPE OR ARTICLE TYPE ====== */}
                                {articleExtended.live_active && (
                                    <LiveWidget initialData={articleExtended} articleId={article?.id} />
                                )}
                                {/* // ========= PREV-NEXT TYPE OR ARTICLE TYPE ====== */}
                                {isPrevNext ? (
                                    <>
                                        <div ref={contentRef} />
                                        <HtmlContent
                                            article={article}
                                            data={article?.body}
                                            page={articlePage}
                                            insertElements={insertElements}
                                            isMainContent
                                        />
                                    </>
                                ) : (
                                    <ArticleContent
                                        className="article-body"
                                        content={articleWithPreview?.body}
                                        insertElements={insertElements}
                                    />
                                )}
                            </Column>

                            {/* <ReadMoreWidget article={moreFromTheColumn?.[0]} /> */}
                            {articleExtended?.cta_title && articleExtended?.cta_url ? (
                                // eslint-disable-next-line react/jsx-indent
                                <Column variant="article.cta">
                                    <ArticleCtaBlock
                                        content={articleWithPreview?.extended_attributes?.cta_title}
                                        title="Saznaj više o temi"
                                        href={articleWithPreview?.extended_attributes?.cta_url}
                                        buttonTitle={articleWithPreview?.extended_attributes?.cta_button_label}
                                    />
                                </Column>
                            ) : null}

                            <Column variant="article.qpq" className="end-article">
                                <ExchangeWidget
                                    cardIdPrefix="end_article"
                                    itemId={id}
                                    exchangeKey={endArticleWidgetIds}
                                    // title="Iz drugih medija new"
                                    titleSizeVariant="1x1"
                                    variant={exchangeWidgetVariants.ENDARTICLE_ALPHA}
                                />
                            </Column>
                        </Row>

                        {/* ========= CONTENT SIDEBAR ====== */}

                        <Row variant="article.mainContent.aside" id="aside">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                {!isPrevNext && <AdSlot {...halfpage2} isSliding={false} />}
                                <Column variant="article.ads.floating">
                                    {isPrevNext ? (
                                        <AdSlot {...halfpage2alt} />
                                    ) : (
                                        <SlidingWrapper {...halfpageSlidingProps} enabled>
                                            <MoreFromTheColumnBox
                                                variant={moreFromTheColumnBoxVariants.BETA}
                                                items={moreFromTheColumn ?? []}
                                                slice={[0, 3]}
                                            />
                                        </SlidingWrapper>
                                    )}
                                </Column>
                            </Media>
                        </Row>
                    </Row>
                    {/* // ========= PREV-NEXT TYPE COMMANDS ====== */}
                    {isPrevNext && (
                        <PrevNextArticle
                            Content={Content}
                            article={article}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            blockList={blockList}
                        />
                    )}
                    {/* // ========= END OF PREV-NEXT TYPE COMMANDS ====== */}
                    <Row variant="article.meta" id="article_meta_wrapper">
                        <ArticleMeta tags={articleTags} id="article_meta" />
                    </Row>
                    {/* ========= BELOW CONTENT ====== */}
                    {/* ========= 1 - mobile & desktop - Voyo Widget ====== */}
                    <WidgetVoyoSlider isWide id={voyoWidgetIds[0]} />
                    <Divider />
                    <TaboolaProvider>
                        <TaboolaFirstFrame />
                        <Media lessThan="md" scaleDown>
                            {/* ========= 2 - mobile - BillboardBottom ====== */}

                            <Row variant="article.ads.billboard">
                                <AdSlot {...articleBottom} mobileOnly />
                            </Row>

                            {/* ========= 4 - mobile - HT Listalica ====== */}
                            {/* @TODO: remove listalice after required dates  */}

                            {/* <RenderOnDay days={['wednesday', 'thursday']} years={['2023']}>
                                <Row variant="article.listalica">
                                    <AntiClsFrame id="below_content_third_party" minHeightXs="375px" minHeightMd="410px">
                                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.T_HT} />
                                    </AntiClsFrame>
                                </Row>
                            </RenderOnDay> */}

                            {/* {articleWithPreview?.extended_attributes?.display_third_party_content && (
                            <Column variant="article.linker">
                                <MonetizeAd isMobile userId={44280} siteId={106241} widgetId={107586} />
                            </Column>
                        )} */}
                        </Media>
                        {/* ========= 4 - desktop - HT Listalica ====== */}
                        {/* @TODO: remove listalice after required dates  */}

                        {/* <Media greaterThan="md" scaleDown>
                            <RenderOnDay days={['wednesday', 'thursday']} years={['2023']}>
                                <Row variant="article.listalica">
                                    <AntiClsFrame id="below_content_third_party" minHeightXs="375px" minHeightMd="410px">
                                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.T_HT} />
                                    </AntiClsFrame>
                                </Row>
                            </RenderOnDay>
                        </Media> */}

                        {/* {articleWithPreview?.extended_attributes?.display_third_party_content && (
                            <Column variant="article.linker">
                                <MonetizeAd isDesktop userId={44280} siteId={106241} widgetId={107586} />
                            </Column>
                        )} */}
                        {/* ========= 6 - mobile & desktop - Linker 2. widget ====== */}
                        {articleWithPreview?.extended_attributes?.display_third_party_content && (
                            <Column variant="article.linker" id="linker_wrapper">
                                <AdNow />
                            </Column>
                        )}

                        {/* ========= 7 - mobile - QPQ 2. set ====== */}
                        <Media lessThan="md" scaleDown>
                            <Column variant="article.qpq">
                                <ExchangeWidget
                                    cardIdPrefix="end_article_mobile"
                                    itemId={id}
                                    exchangeKey={widgetIdSets?.articleHigherWidget?.mobile}
                                    // title="Iz drugih medija new"
                                    titleSizeVariant="1x1"
                                    variant={exchangeWidgetVariants.EPSILON}
                                />
                            </Column>
                        </Media>

                        <Row variant="article.mightBeInterestedAlt">
                            {/* ========= 5 - desktop & mobile - Najčitanije ====== */}
                            <YouMightBeInterestedBox title="Najčitanije" excludeId={articleWithPreview.id} />

                            {/* ========= 8 - desktop - Banner ====== */}
                            <Media greaterThan="sm" scaleDown>
                                <Column variant="article.ads.floating">
                                    <AdSlot {...halfpage3} />
                                </Column>
                            </Media>
                        </Row>

                        {/* ========= 9 - desktop - Billboard2 ====== */}
                        <Row variant="article.ads.billboard">
                            <AdSlot {...billboard2} />
                        </Row>

                        {/* ========= 11 - mobile - Još iz rubrike ====== */}
                        <Media lessThan="md" scaleDown>
                            <Column variant="article.moreArticles">
                                <MoreFromTheColumnBox
                                    items={moreFromTheColumn ?? []}
                                    article={articleWithPreview}
                                    slice={[3, 6]}
                                    variant={moreFromTheColumnBoxVariants.BETA}
                                />
                            </Column>
                        </Media>

                        {/* ========= 12 - mobile & desktop - Regionalci ====== */}

                        <Column variant="article.regional">
                            <ExchangeWidget
                                cardIdPrefix="regional"
                                itemId={id}
                                exchangeKey={widgetIdSets?.regionalWidget}
                                title="Regionalni portali"
                                titleSizeVariant="1x1"
                                variant={exchangeWidgetVariants.DELTA}
                            />
                        </Column>

                        {/* ========= 13 -  mobile - QpQ ====== */}

                        <Media lessThan="md" scaleDown>
                            <Column variant="article.regional">
                                <ExchangeWidget
                                    itemId={id}
                                    cardIdPrefix="regional_mobile"
                                    exchangeKey={widgetIdSets?.articleLowerWidget?.mobile}
                                    // title="Iz drugih medija"
                                    titleSizeVariant="1x1"
                                    variant={exchangeWidgetVariants.EPSILON}
                                />
                            </Column>
                        </Media>

                        {/* ========= 14 - mobile & desktop - Izdvojeno ====== */}
                        <SelectedArticlesBox excludeId={articleWithPreview.id} />

                        {/* ========= 15 - desktop - Još iz rubrike & 16 - mobile - ====== */}
                        <Media greaterThan="sm" scaleDown>
                            <Row variant="article.moreArticles">
                                <MoreFromTheColumnBox
                                    items={moreFromTheColumn ?? []}
                                    article={articleWithPreview}
                                    slice={[3, 6]}
                                    variant={moreFromTheColumnBoxVariants.ALPHA}
                                />
                            </Row>
                        </Media>

                        {/* ========= 19 -  mobile & Desktop - Webshop listalica ====== */}

                        {/* <Column variant="article.listalica">
                            <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                        </Column> */}
                        <TaboolaSecondFrame />
                    </TaboolaProvider>
                    <Divider />
                </Row>

                <ModalGallery
                    title={article.title}
                    subtitle={article.subtitle}
                    categoryColor={article?.sitemap?.node?.categoryColor}
                />

                {/* ========= Out of page Ads ====== */}

                <Row variant="zeroDimensions">
                    <AdSlot {...outOfPage1} />
                    <AdSlot {...outOfPage2} />
                    <AdSlot {...outOfPage3} />
                    {!isPrevNext && <AdSlot {...staticAds.anchor} />}
                    <AdSlot {...interstitial} />
                </Row>
            </GalleryContext.Provider>
        </BaseLayout>
    )
}

Article.propTypes = {
    ssrData: PropTypes.shape({
        lengthScore: PropTypes.shape({
            score: PropTypes.number,
            lengthCategory: PropTypes.oneOf(['short', 'medium', 'long'])
        }),
        article: PropTypes.oneOfType([() => null, PropTypes.object]),
        author: PropTypes.oneOfType([() => null, PropTypes.object]),
        articleVideo: PropTypes.shape({}),
        articleGallery: PropTypes.shape({}),
        moreFromTheColumn: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})])
    }).isRequired,
    context: PropTypes.shape({
        embeds: PropTypes.shape({
            entities: PropTypes.shape({}).isRequired
        }).isRequired
    }).isRequired,
    navigation: PropTypes.shape({}).isRequired
}

Article.displayName = 'Article'

export default Article
